import { render, staticRenderFns } from "./ChoicePublicPayment.vue?vue&type=template&id=6298a8fb&scoped=true&"
import script from "./ChoicePublicPayment.vue?vue&type=script&lang=js&"
export * from "./ChoicePublicPayment.vue?vue&type=script&lang=js&"
import style0 from "./ChoicePublicPayment.vue?vue&type=style&index=0&id=6298a8fb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6298a8fb",
  null
  
)

export default component.exports